import {
  ADD_CLIENT_SUCCESS,
  ADD_COMPANY_SUCCESS,
  Company,
} from 'src/store/clients/types';
import { appAPI, ApiTags, ApiMethods } from '.';
import { Client } from 'src/constants';
import { RootState } from 'src/store';
import { MODIFY_INTERNAL_USERS } from 'src/store/users/types';

type getCompaniesInput = {
  ignoreAccess?: boolean;
};

type CompanyInput = {
  name: string;
  clients: Array<{ name: string; email: string }>;
};

export type UnprocessedClientResponse = {
  client: {
    name: string;
    email: string;
  };
  error: string;
};

type CreateCompanyResponse = {
  company: Company;
  clients: Client[];
  unprocessedClients: Array<UnprocessedClientResponse>;
};

export const companiesApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    createCompany: build.mutation<CreateCompanyResponse, CompanyInput>({
      query: (newCompany) => ({
        path: `/companies`,
        method: ApiMethods.post,
        options: {
          body: newCompany,
        },
      }),
      invalidatesTags: [
        ApiTags.companies,
        ApiTags.clientCompanies,
        ApiTags.clientUsers,
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const state = getState() as RootState;
          const currentUserId = state.user.id;
          const { internalUsers } = state.users;
          const { data } = await queryFulfilled;
          if (data.clients.length) {
            dispatch({ type: ADD_CLIENT_SUCCESS, payload: data.clients });
          }
          if (data.company) {
            dispatch({ type: ADD_COMPANY_SUCCESS, payload: [data.company] });
            dispatch({
              type: MODIFY_INTERNAL_USERS,
              payload: internalUsers.map((user) => {
                if (user.id === currentUserId) {
                  return {
                    ...user,
                    companyAccessList: [
                      ...(user.companyAccessList ?? []),
                      data.company,
                    ],
                  };
                }
                return user;
              }),
            });
          }
        } catch (e) {
          // nothing for now
        }
      },
    }),
    getCompanies: build.query<Company[], getCompaniesInput>({
      query: (getCompaniesInput) => ({
        path: `/companies`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: getCompaniesInput,
        },
      }),
      providesTags: [ApiTags.clientCompanies],
    }),
  }),
});

export const { useGetCompaniesQuery, useCreateCompanyMutation } = companiesApi;
